const ApiUrl = () => {

    let link = '';
    if ( window.location.hostname === 'localhost') {
        link = 'https://api.coznaczyww.pl'
    } else {
        link = 'https://api.coznaczyww.pl'
    }

    return link;
}

export default ApiUrl;