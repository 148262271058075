const Play = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="63px" height="73px">
        <path fill-rule="evenodd"  fill="rgb(255, 214, 43)"
        d="M62.755,36.645 L0.755,72.770 L0.755,0.520 L62.755,36.645 Z"/>
    </svg>
)

export default Play;