const PlayMobile = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="29px" height="34px">
        <path fill-rule="evenodd"  fill="rgb(255, 214, 43)"
        d="M28.908,16.728 L0.814,33.118 L0.814,0.337 L28.908,16.728 Z"/>
    </svg>
)

export default PlayMobile;