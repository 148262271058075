import React, { useEffect, useState } from "react"
import { Routes, Route, Link, useLocation } from "react-router-dom";
import HomePage from './pages/Home';
import WelcomePage from './pages/Welcome';
import Layout from './components/Layout';
import Get from './services/Get';

function App() {
  let [ load, setLoad ] = useState(false);
  let [ ver, setVer ] = useState(true);
  let location = useLocation();

  // useEffect(() => {
  //   if(!load) {
  //     if(Get("v") == "form") {
  //       setVer(false);
  //     }else{
  //       setVer(true);
  //     }
  //   }
  // }, [load])

  useEffect(() => {
    window.addEventListener("scroll", () => {
      var items = document.querySelectorAll(".animate-item");
      for (var i = 0; i < items.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = items[i].getBoundingClientRect().top;
        var elementVisible = 150;
        if (elementTop < windowHeight - elementVisible) {
          items[i].classList.add("animation");
        } else {
          items[i].classList.remove("animation");
        }
      }
    });
  }, []);

  return (
    <Layout ver={ver}>
      <Routes>
        {/* <Route path="/" element={ ver ? <WelcomePage /> : <HomePage />} /> */}
        <Route path="/" element={ <HomePage />} />
      </Routes>
    </Layout>
  );
} 


export default App;
