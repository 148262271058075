import { useState, useEffect } from "react";
import Arrow from './../../svg-components/SelectArrow';

let Select = ( props ) => {
    let [ load, setLoad ] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [status, setStatus] = useState(false);
    let [ error, setError ] = useState( false );

    useEffect(() => {
        if(!load) {
            if(props.selectIndex || props.selectIndex === 0) {
                setActiveIndex(props.selectIndex);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(load) {  
            handleChangeIndex("reset");
        }
    }, [props.reset])
    
    const handleChangeOpen = () => {
        setStatus(!status) 
    }

    const handleChangeIndex = (i) => {
        if(i == "reset") {
            setActiveIndex(-1);
            setStatus(!status)
            props.setFunction(null);
            setError(false);
        }else{
            setActiveIndex(i);
            setStatus(!status)
            props.setFunction(i);
            setError(false);
        }
    }

    useEffect(() => {
        if ( load && props.forceValidate ) {
            validate();
        }
      }, [props.forceValidate])

    const validate = () => {
        if ( activeIndex === -1 ) {
        setError(true);
        } else {
            setError(false);
        }
    }

    return (
        <div className={"input-wrapper select-wrapper" + (props.class ? " " + props.class : "") + ( error ? ' error' : '' ) }>
            <div className={"chosen" + (status ? ' active' : '')} onClick={ () => handleChangeOpen() }>
                { activeIndex >= 0 &&
                    <span className="selected" id={props.id ? props.id : ""}>{props.data[activeIndex].title}</span>
                }
                { activeIndex < 0 &&
                    <span>{ props.placeholder }</span>
                }  
                <span className={"dropdown-bird" + (status ? ' active' : '')}><Arrow /></span>
            </div>
            <div className={"options" + (status ? ' active' : '')} >
                { props.data.map( (val, i) => <p key={ i } onClick={ () => handleChangeIndex( i ) } > { val.title } </p> ) }
            </div> 
        </div>
        
    )
} 

export default Select; 