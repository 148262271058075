import React, { useState, useEffect } from "react"
import TransparentImage from './../../images/transparent-footer.jpg';
import TransparentImageTablet from './../../images/transparent-footer-tablet.jpg';
import TransparentImageMobile from './../../images/transparent-footer-mobile.jpg';
import wwImage from './../../images/ww-footer.png';
import wwImageTablet from './../../images/ww-footer-tablet.png';
import wwImageMobile from './../../images/ww-footer-mobile.png';
import Logo from './../../images/wedel_logo.svg';
import LogoSvg from './../../svg-components/Logo';
import TikTok from './../../svg-components/TiktokIcon';
import Instagram from './../../svg-components/InstaIcon';
import Facebook from './../../svg-components/FbIcon';
import Youtube from './../../svg-components/ytIcon';
import Cookies from './../../images/coznaczyww.pl - Polityka cookies.pdf';
import Regulations from './../../images/Regulamin konkursu.pdf';

const Footer = () => {
    return (
        <>
            <footer>
                <img class="transition-bg desktop" src={TransparentImage} alt="" />
                <img class="transition-bg tablet" src={TransparentImageTablet} alt="" />
                <img class="transition-bg mobile" src={TransparentImageMobile} alt="" />
                <div className="container">
                    <div class="ww-img desktop animate-item">
                        <img src={wwImage} alt="" />
                    </div>
                    <div class="ww-img tablet animate-item">
                        <img src={wwImageTablet} alt="" />
                    </div>
                    <div class="ww-img mobile animate-item">
                        <img src={wwImageMobile} alt="" />
                    </div>
                    <div className="wrapper">
                        <div className="row">
                            <div className="col">
                                <div className="logo">
                                    {/* <img src={Logo} alt="wedel" /> */}
                                    <LogoSvg />
                                </div>
                            </div>
                            <div className="col">
                                <div className="socials socials-not-mobile">
                                    {/* <a className="tiktok" href="http://google.com" target="_blank"><TikTok /></a>
                                    <a className="insta" href="http://google.com" target="_blank"><Instagram /></a>
                                    <a className="fb" href="http://google.com" target="_blank"><Facebook /></a>
                                    <a className="yt" href="http://google.com" target="_blank"><Youtube /></a> */}
                                    {/* <a className="tiktok" href="http://google.com" target="_blank"><TikTok /></a> */}
                                    <a className="insta" href="https://www.instagram.com/e.wedel/" target="_blank"><Instagram /></a>
                                    <a className="fb" href="https://www.facebook.com/czekolada/" target="_blank"><Facebook /></a>
                                    <a className="yt" href="https://www.youtube.com/channel/UCWiHttSGvOn4R6HxQ4AiEDA" target="_blank"><Youtube /></a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="reg">
                                <a href={Regulations} target="_blank" className="desktop">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="23px" height="30px">
                                        <path fill-rule="evenodd"  fill="rgb(222, 23, 29)"
                                        d="M22.623,14.684 L0.685,29.122 L0.685,0.247 L22.623,14.684 Z"/>
                                    </svg>
                                    REGULAMIN
                                </a>
                                <a href={Regulations} target="_blank" className="tablet">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="17px" height="22px">
                                        <path fill-rule="evenodd"  fill="rgb(222, 23, 29)"
                                        d="M16.864,10.625 L0.833,21.188 L0.833,0.062 L16.864,10.625 Z"/>
                                    </svg>
                                    REGULAMIN
                                </a>
                                <a href={Regulations} target="_blank" className="mobile">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="17px" height="22px">
                                        <path fill-rule="evenodd"  fill="rgb(222, 23, 29)"
                                        d="M16.864,10.625 L0.833,21.188 L0.833,0.062 L16.864,10.625 Z"/>
                                    </svg>
                                    REGULAMIN
                                </a>
                            </div>
                        </div>
                        <div className="row row-links-info">
                            <div className="links">
                                {/* <a href="https://google.com" target="_blank">POLITYKA PRYWATNOŚCI</a>
                                <p>|</p> */}
                                <a href={Cookies} target="_blank">POLITYKA COOKIES</a>
                            </div>
                            <div className="socials mobile">
                                {/* <a className="tiktok" href="http://google.com" target="_blank"><TikTok /></a> */}
                                <a className="insta" href="https://www.instagram.com/e.wedel/" target="_blank"><Instagram /></a>
                                <a className="fb" href="https://www.facebook.com/czekolada/" target="_blank"><Facebook /></a>
                                <a className="yt" href="https://www.youtube.com/channel/UCWiHttSGvOn4R6HxQ4AiEDA" target="_blank"><Youtube /></a>
                            </div>
                            <div className="info">
                                <p><strong>Organizatorem konkursu jest Digital Kingdom Sp. z o. o.</strong></p>
                                <p>ul. Czerniakowska 73/79, 00-718 Warszawa, NIP: 7010556576, REGON: 363900426</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )

}



export default Footer;