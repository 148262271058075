const WwMenu = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="161.395px" height="122.395px">
        <path fill-rule="evenodd"  stroke="rgb(222, 23, 29)" stroke-width="2.716px" stroke-linecap="butt" stroke-linejoin="miter" fill="rgb(222, 23, 29)"
        d="M153.247,82.265 C153.247,82.265 150.093,83.110 144.832,84.519 C142.197,85.225 140.691,82.290 137.131,83.244 C135.745,83.615 134.280,84.008 132.781,84.409 L132.781,84.409 C132.781,84.409 86.800,96.729 53.079,105.763 C45.232,107.866 40.264,109.197 40.264,109.197 L40.264,109.197 C38.766,109.598 37.301,109.991 35.915,110.362 C32.354,111.316 32.518,114.611 29.883,115.317 C24.622,116.726 21.468,117.571 21.468,117.571 L16.502,99.037 L16.502,99.037 L3.605,50.912 C3.605,50.912 6.759,50.067 12.020,48.657 C14.655,47.952 16.161,50.887 19.722,49.932 C38.323,44.949 67.748,37.065 89.295,31.293 C100.943,28.172 112.151,25.169 120.937,22.815 C124.498,21.861 124.334,18.566 126.969,17.860 C132.230,16.450 135.384,15.605 135.384,15.605 L148.280,63.730 L148.280,63.730 L153.247,82.265 Z"/>
        <path fill-rule="evenodd"  stroke="rgb(222, 23, 29)" stroke-width="1.358px" stroke-linecap="butt" stroke-linejoin="miter" fill="rgb(255, 207, 35)"
        d="M157.988,69.075 C157.988,69.075 154.834,69.919 149.573,71.329 C146.939,72.035 145.432,69.100 141.872,70.054 C109.676,78.680 45.006,96.007 45.006,96.007 L45.005,96.006 C43.507,96.408 42.042,96.800 40.656,97.171 C37.095,98.126 37.259,101.421 34.624,102.127 C29.363,103.536 26.210,104.381 26.210,104.381 L13.313,56.256 L13.313,56.256 L8.347,37.722 C8.347,37.722 11.500,36.877 16.761,35.468 C19.396,34.762 20.902,37.697 24.463,36.743 C25.848,36.371 27.314,35.979 28.812,35.577 L28.812,35.577 C28.812,35.577 65.848,25.654 97.820,17.088 C111.595,13.398 121.329,10.790 121.329,10.790 L121.329,10.790 C122.827,10.389 124.293,9.996 125.678,9.625 C129.239,8.671 129.075,5.376 131.710,4.670 C136.971,3.260 140.125,2.415 140.125,2.415 L153.021,50.541 L153.021,50.541 L157.988,69.075 Z"/>
    </svg>
)

export default WwMenu;