const onClickScroll = (hash) => {

    let elements = document.querySelectorAll(hash);
    if( elements.length > 0 ) {
        let topElement = elements[0].getBoundingClientRect().top;
        let windowScroll = window.scrollY;
        window.scroll({
            behavior: 'smooth',
            left: 0,
            top: windowScroll + topElement - 10
        });
    }
}

export default onClickScroll;