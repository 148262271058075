const Logo = () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 170.53 92.72" xmlSpace="preserve">
    <g>
        <path class="st0" d="M163.33,27.54c-1.16,0.33-2.5,0.81-3.99,1.41l-0.63,0.25c-1.9,0.74-3.93,1.67-5.91,2.69
            c-1.99,0.99-3.68,1.92-5.1,2.76l-1.95,1.13l-3.87,2.38c-2.46-1.3-4.45-3.82-4.63-7.44c5.05-6.86,10.01-14.91,12.3-19.85
            c1.11-2.43,1.99-4.87,2.22-5.75c0.81-3.15-0.58-5.46-2.76-5.05c-2.08,0.39-3.03,1.78-4.05,3.06c-1.6,2.01-5.51,8.98-8.47,15.79
            c-1.09,2.5-2.39,6.12-2.94,7.94c-0.53,1.83-1.02,5.81-1.04,6.39c-1.27,1.58-2.52,2.99-3.8,4.21c-0.83,0.79-1.67,1.48-2.55,2.09
            c-2.18,1.07-4.33,1.18-5.19-1.46c2.36-1.37,5.19-3.68,6.72-5.97c0.67-1,1.57-2.64,1.81-3.73c0.42-1.99-0.23-3.77-2.48-3.87
            c-1.6-0.07-3.52,1.23-4.54,1.97c-1.69,1.21-3.22,3.13-4.56,5.23c-0.93,1.5-1.83,3.5-2.25,5.58l-0.09,0.28
            c-0.72,1.16-1.3,2.02-1.9,2.8l-0.67,0.85c-0.81,0.99-1.76,2.2-2.92,2.45c-0.05,0-0.21,0.02-0.32-0.12
            c-0.76-1.14,0.81-7.81,1.83-11.09l0.79-2.53c0,0,2.2-2.45,2.9-3.33c2.96-3.73,5.23-6.71,6.93-10.53c0.67-1.51,1.48-3.29,1.39-5
            c-0.16-2.31-2.22-3.36-4.21-2.24c-1.69,0.97-2.9,2.94-3.94,4.65c-3.26,5.28-5.33,11.74-7.48,17.34c-3.03,0.35-5.35,1.57-7.44,3.01
            c-1.92,1.3-3.93,3.08-5.17,5.14c-0.84,1.37-1.51,2.53-2.02,4.12c-0.16,0.53-0.42,2.08-0.42,2.08c-0.28,0.51-1.35,1.78-1.88,2.45
            c-1.67,2.15-7.37,7.71-8.52,2.59c2.36-1.37,5.21-3.68,6.72-5.97c0.69-1.02,1.58-2.64,1.81-3.73c0.42-1.99-0.21-3.77-2.46-3.87
            c-1.6-0.09-3.54,1.23-4.54,1.97c-1.71,1.21-3.24,3.12-4.56,5.23c-2.01,3.19-3.84,8.64-1.2,12.34c0.65,0.7,1.34,1.09,2.23,1.16
            c2.71,0.18,4.79-1.53,6.28-2.64c1.16-1.09,2.15-2.11,3.13-3.24c0.97-1.13,2.18-2.5,2.8-3.52c0.16,3.57,2.85,4.82,5.53,3.91
            c1.32-0.51,2.82-2.09,3.87-3.41c1.02-1.3,1.9-2.76,2.76-3.98c-0.77,3.27,1.57,6.23,4.58,4.15c0.9-0.6,2.13-2.15,2.97-3.31l0.6-0.84
            v0.02c0.97-1.34,1.9-2.62,2.45-3.43c0.18,1.09,0.95,4.22,5.12,4c3.55-0.19,6.51-2.8,8.48-4.95c0.28-0.23,2.15-2.62,3.63-4.51
            l0.12,0.74c0.56,2.09,1.73,3.84,3.47,5.09c-3.84,2.31-12.58,7.29-21.05,12.02l-11.99,3.34c-1.92,0.55-12.46,3.66-14.27,4.22
            c-10.09,3.12-18.78,5.6-27.63,9.31c-4.95,2.08-6.34,2.62-9.4,4.07c-1.73,0.79-16.4,7.25-18.52,12.69
            c-1.48,3.78-0.16,6.35,5.74,5.54c3.87-0.55,9.73-2.83,14.15-4.68c4.12-1.65,9.36-4.1,15.31-7.04c9.05-4.47,19.61-10.1,29.8-15.58
            c2.85-1.53,10.37-5.72,18.09-10.03c3.33-0.9,14.78-3.85,16.19-4.21c4.98-1.25,4.93-2.46,0.69-1.95c-2.31,0.28-5.86,1.14-9.61,2.08
            l13.52-7.55l1.55-0.9c3.87,1.39,9.31,0.9,16.49-1.51c2.75-0.93,5.79-2.27,8.25-4.03c1.2-0.77,2.27-1.57,3.15-2.34
            c1.02-0.88,1.95-1.83,2.73-2.96C171.47,29.05,171.18,25.32,163.33,27.54 M144.69,8.46c1.65-2.96,2.76-4.38,3.84-4.28
            c1.21,0.11,0.14,2.78-0.42,4.19c-0.39,0.97-0.91,2.06-1.46,3.27c-2.08,4.05-5.61,10.31-9.26,15.5
            C138.3,20.44,142.51,12.35,144.69,8.46 M119.91,11.5c0.3-0.35,0.53-0.58,0.92-0.51c0.25,0.05,0.32,0.37,0.35,0.55
            c0.11,0.7-0.18,1.53-0.42,2.31c-1.29,4.15-4.24,8.48-6.79,11.21C115.54,20.16,117.87,14.13,119.91,11.5 M86.73,39.58
            c0.42-0.32,0.91-0.67,1.41-0.63c1.43,0.58-0.55,4.31-1.81,6c-1.11,1.48-2.6,2.87-3.98,3.47C82.91,44.38,84.13,41.72,86.73,39.58
             M106.25,36.44c-1.11,2.82-1.37,4.44-3.19,6.97c-1.09,1.5-3.19,4.12-4.72,3.49c-2.08-1.07-0.16-6.58,1.71-8.96
            c1.83-2.34,3.93-4.24,7.23-5.16C107.15,33.63,106.46,35.88,106.25,36.44 M125.17,27.48c0.4-0.33,0.91-0.69,1.39-0.65
            c1.43,0.58-0.55,4.33-1.81,6c-1.09,1.48-2.59,2.87-3.98,3.47C121.33,32.29,122.55,29.63,125.17,27.48 M97.38,63.32
            c-10.17,5.46-20.7,11.07-29.71,15.54c-4.35,2.15-8.31,4.1-11.74,5.53c-2.08,0.86-4.88,1.99-6.99,2.66
            c-2.31,0.74-6.21,0.76-6.67-1.13c-0.58-2.43,3.54-5.65,13.2-10.53c1.3-0.65,2.82-1.35,4.42-2.08l2.34-0.99
            c13.87-5.81,31.86-10.65,45.44-14.68C103.36,60.06,99.63,62.1,97.38,63.32 M165.46,32.5c-3.06,3.06-6.07,4.63-9.08,5.67
            c-2.59,0.91-7.44,2.15-11.84,1.02c0.79-0.49,1.74-1.14,2.34-1.48c3.87-2.29,8.59-4.86,12.64-6.44c2.06-0.81,4.29-1.41,5.4-1.41
            C166.42,29.86,167.11,30.85,165.46,32.5 M40.02,64c0.49-0.33,1.3-0.44,1.9,0c0.47,0.35,0.65,1.09,0.6,2.11
            c-0.09,2.53-2.87,4.45-3.92,2.16C37.88,66.73,38.86,64.8,40.02,64 M34.53,62.35c-0.21-2.11-0.95-4.29-2.78-5.74
            c-2.18-1.74-5.03-2.01-7.44-1.37c-3.78,1-6.85,3.41-8.57,7.55c-2.08,5.05-0.53,9.68,1.64,10.14c0.49,0.12,0.37-0.91,0.26-1.3
            c-1.32-5.14,2.59-11.33,6.6-13.18c1.69-0.77,3.61-0.7,5.09,0.33c4.22,2.97,2.32,11.32-1.67,16.19c-2.55,3.12-5.91,6.09-10.37,6.92
            c-1.06,0.19-2.29,0.26-3.19,0.09c-5.42-1.13-7.57-8.06-5.95-14.33c1.16-4.45,4.56-9.35,8.25-12.55c2.62-2.27,6.23-4.43,9.66-6.05
            c0.83-0.39,3.31-1.2,3.01-2.31c-0.21-0.72-1.58-0.56-2.38-0.91c-1.35-0.6-2.59-1.99-2.94-3.61c4.68-1.88,7.5-3.61,8.01-3.94
            c2.87-1.88,6.88-5.14,7.74-8.68c1.13-4.72-2.11-7.15-6.35-6.58c-2.09,0.28-3.84,0.88-5.6,1.76c-5.1,2.55-10.19,9.52-9.86,16.4
            c-3.91,0.9-8.87-0.3-8.5-5.05c0.21-2.94,2.83-5.32,4.72-6.65c1.53-1.09,2.76-0.99,2.96-1.88c0.04-0.21-0.11-0.47-0.3-0.58
            c-0.32-0.14-0.91-0.02-1.25,0.04c-2.38,0.41-5,1.69-6.92,3.2c-1.57,1.23-3.13,3.06-3.8,4.91c-2.9,8.13,6.79,9.5,13.94,8.29
            c0.86,1.87,2.71,3.11,4.33,4.19c-9.01,3.66-17.26,9.26-21.3,18.36c-3.17,7.16-1.69,17.6,5.37,20.82
            c7.92,3.59,14.66-0.51,19.78-5.16c3.54-3.22,5.75-7.81,7.02-12.41C34.36,67.1,34.76,64.6,34.53,62.35 M27.6,30.18
            c1.71-2.06,4.45-4.08,6.41-3.87c2.23,0.23,1.88,2.57,1.11,4.07c-0.28,0.51-0.81,1.23-1.2,1.71c-2.5,2.97-6.51,5.84-10.37,7.25
            C24.25,35.97,25.57,32.62,27.6,30.18 M46.62,25.94c0.58-0.72,1.44-1.55,2.06-2.09c1.81-1.53,6.02-3.15,8.39-1.46
            c1.9,1.37,2.57,5.17,2.36,8.45c-0.16,2.48-0.7,4.98-1.27,7.41c-1.55,6.81-3.8,14.82-5.75,21.15c-0.05,0.14-0.18,0.44-0.07,0.58
            c0.11,0.14,0.47-0.28,0.76-0.79c0.56-0.99,1.2-2.75,1.87-4.17c4.59-9.91,9.61-23.74,14.27-28.72c0.79-0.69,1.97-1.2,2.89-0.63
            c0.23,0.12,0.47,0.58,0.49,0.85c0.3,2.43-1.69,10.58-2.75,15.22l-0.48,2.22l-2.13,10.1c-0.12,0.65-0.18,1.18,0,1.23
            c0.58,0.12,1.62-2.43,2.22-3.7c1.79-3.85,3.5-8.36,5.12-12.55c2.18-5.61,4.24-11.3,7.89-16.02c3.73-4.86,8.08-8.45,13.87-11.6
            c3.84-2.08,8.15-3.84,12.94-6c2.38-1.07,4.86-2.04,6.3-3.87c0.07-0.09,0.3-0.44,0.58-0.39c0.58,0.11,0.51,1.09,0.44,1.67
            c-0.25,2.29-2.31,4.75-3.92,6.04c-1.02,0.79-2.27,1.5-3.43,2.2c-5.83,3.61-13.39,5.95-18.55,10.12c-1.3,1.04-2.64,2.15-3.91,3.43
            c-8.15,8.18-11.95,20.45-16.77,31.22c-0.88,1.73-1.81,3.56-2.97,5.19c-0.74,1.04-2.29,2.9-3.7,2.43c-1.85-0.88-1.06-4.59-0.58-7.11
            c0.7-3.71,1.25-6.92,2.08-10.51c0.42-1.79,0.7-3.31,1.04-4.84c0.23-1.11,0.56-2.55,0.74-3.61c0.18-1.09,0.65-2.83,0.35-3.04
            c-0.42-0.14-0.95,1.18-1.39,1.88c-2.39,5.26-4.91,10.42-7.11,15.81c-1.23,3.11-2.46,5.81-3.93,8.76c-0.99,2.17-2.27,4.52-4.08,6.39
            c-0.83,0.86-2.71,2.41-3.49,0.53c-0.28-0.69,0-1.88,0.18-2.8c0.44-2.27,1.23-5.07,1.78-7.18c1.65-6.39,3.26-12.65,4.54-19.08
            c0.65-3.19,1.43-7.9,0.58-10.58c-0.83-2.67-3.8-2.04-5.72-0.62c-0.77,0.56-1.8,1.76-2.48,2.36c-0.16,0.21-0.76,0.28-0.88-0.18
            C44.69,28.56,45.74,27.06,46.62,25.94 M42.61,13.65l-0.14-0.09v-1.78l0.37,0.25l0.55,0.3l0.55,0.18l0.51,0.05l0.41-0.05l0.35-0.12
            l0.32-0.19l0.23-0.26l0.12-0.28l0.05-0.32l-0.03-0.25l-0.07-0.23l-0.14-0.21l-0.19-0.18l-0.35-0.23l-0.39-0.14l-0.46-0.04
            l-0.39,0.02l-0.28,0.03l-0.19,0.05l-0.33,0.18V5.45h4.1v1.53h-2.53v1.55l0.6,0.05l0.55,0.14l0.51,0.25l0.44,0.33l0.02,0.02
            l0.37,0.42l0.25,0.48l0.16,0.53l0.05,0.56v0.03l-0.05,0.56l-0.14,0.55l-0.26,0.49l-0.35,0.42l-0.02,0.02l-0.44,0.33l-0.51,0.25
            l-0.56,0.14l-0.6,0.05h-0.03l-0.67-0.05l-0.69-0.18L42.61,13.65z M55.48,14.16h-1.65V5.54h1.65V14.16z M32.95,12.58l0.26,0.44
            l0.35,0.39h0.02v0.02L34,13.74l0.49,0.23l0.53,0.14l0.56,0.05h0.03l0.56-0.05l0.51-0.12l0.49-0.25l0.41-0.3v-0.02h0.02l0.35-0.37
            l0.25-0.44l0.16-0.49l0.05-0.53v-0.03l-0.05-0.51l-0.14-0.48l-0.26-0.44l-0.35-0.37l-0.16-0.12l0.21-0.18l0.35-0.47l0.21-0.53
            L38.3,7.9V7.86l-0.05-0.49L38.1,6.89l-0.23-0.44l-0.33-0.37V6.07l-0.02-0.02l-0.41-0.3l-0.46-0.23L36.17,5.4l-0.51-0.05h-0.03
            L35.09,5.4L34.6,5.54l-0.46,0.23l-0.41,0.3v0.02h-0.02l-0.35,0.37l-0.25,0.44l-0.14,0.48l-0.05,0.49V7.9L33,8.44l0.18,0.49h0.02
            v0.02l0.19,0.3l0.26,0.32l0.09,0.07l-0.18,0.12l-0.35,0.37l-0.26,0.44l-0.16,0.48l-0.05,0.51v0.03l0.05,0.51L32.95,12.58z
             M34.62,7.63l0.09-0.23l0.19-0.21l0.18-0.12l0.25-0.09l0.3-0.03l0.3,0.03l0.25,0.09l0.18,0.12l0.19,0.23l0.09,0.23l0.03,0.26
            l-0.03,0.25l-0.09,0.23L36.34,8.6l-0.19,0.14L35.9,8.83l-0.26,0.03l-0.28-0.03l-0.25-0.09L34.92,8.6l-0.19-0.23l-0.11-0.23
            L34.58,7.9L34.62,7.63z M34.46,11.24l0.11-0.25l0.16-0.19l0.25-0.16l0.26-0.11l0.33-0.04l0.35,0.04l0.28,0.11l0.25,0.16l0.18,0.19
            l0.11,0.23l0.04,0.32v0.05l-0.04,0.3l-0.11,0.21l-0.16,0.18l-0.25,0.16l-0.28,0.09l-0.32,0.03l-0.35-0.03l-0.3-0.11l-0.21-0.14
            l-0.19-0.23l-0.11-0.23l-0.03-0.28L34.46,11.24z M6.9,12.42l0.26,0.47l0.37,0.44l0.02,0.02l0.44,0.35l0.53,0.26l0.56,0.14
            l0.58,0.05H9.7l0.6-0.05l0.58-0.14l0.51-0.26l0.46-0.35l0.02-0.02l0.39-0.42l0.28-0.49l0.16-0.56l0.05-0.58v-0.03l-0.05-0.58
            l-0.16-0.55l-0.28-0.49l-0.37-0.44l-0.02-0.02l-0.46-0.35l-0.53-0.26l-0.56-0.14l-0.6-0.05H9.68L9.1,8.41L8.54,8.55L8.01,8.81
            L7.55,9.16L7.53,9.18L7.16,9.62L6.9,10.11l-0.16,0.56l-0.05,0.58v0.03l0.05,0.58L6.9,12.42z M8.26,10.87l0.14-0.35l0.25-0.32
            l0.3-0.21l0.35-0.14L9.73,9.8l0.42,0.05l0.35,0.14l0.3,0.23l0.25,0.3l0.14,0.33l0.05,0.39l-0.04,0.32l-0.07,0.28L11,12.07
            l-0.18,0.21l-0.33,0.26l-0.35,0.14l-0.42,0.05l-0.42-0.05l-0.35-0.14l-0.32-0.23l-0.23-0.3l-0.14-0.35L8.2,11.27L8.26,10.87z
             M27.35,14.16H25.7V5.54h1.65V14.16z M17.86,14.13l0.58-0.18h0.03l0.49-0.26l0.44-0.33l0.02-0.02v-0.02l0.35-0.41l0.26-0.47v-0.02
            l0.18-0.53l0.05-0.55v-0.03l-0.04-0.51l-0.14-0.49l-0.23-0.46V9.83l-0.3-0.4l-0.35-0.33L18.8,8.83l-0.02-0.02l-0.51-0.19
            l-0.58-0.11l-0.67-0.03H15v5.68h1.94L17.86,14.13z M16.47,9.88h0.47l0.69,0.04l0.16,0.05l0.19,0.07l0.02,0.02l0.19,0.11l0.14,0.11
            l0.25,0.3l0.12,0.32l0.05,0.4l-0.05,0.41l-0.12,0.35l-0.23,0.3l-0.26,0.21l-0.16,0.07l-0.23,0.07v-0.02l-0.18,0.04l-0.26,0.02
            l-0.3,0.02h-0.49V9.88z"/>
    </g>
    </svg>    
)

export default Logo;