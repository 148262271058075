export default ({text, close}) => {

    return (
        <div className="popup">
            <div className="popup-wrapper">
                <h2>{text}</h2>
                <a onClick={() => close()}>X</a>
            </div>
        </div>
    )
}
