const Title = ( { title, info, className, text } ) => {

    return (
        <>
        <div className={`title-wrapper ${className ? className : ''}`}>
            {!text &&
                <>
                <h2>{title}</h2>
                {info &&
                    <p>{info}</p>
                }
                </>
            }
            {text &&
                <div dangerouslySetInnerHTML={ { __html: text } } />
            }
        </div>
        </>
    )
}

export default Title;