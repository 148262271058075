const YtIcon = () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 31.16 31.21" xmlSpace="preserve">
    <g id="BACKGROUND">
    </g>
    <g id="Layer_1">
        <g>
            <path class="st0" d="M15.58,0.04c-8.6,0-15.56,6.97-15.56,15.56s6.97,15.56,15.56,15.56s15.56-6.97,15.56-15.56
                S24.18,0.04,15.58,0.04z M24.32,19.13c-0.28,2.08-1.32,2.4-3.37,2.57c-1.77,0.17-3.79,0.21-5.35,0.21c-1.56,0-3.61-0.03-5.35-0.21
                c-2.05-0.17-3.13-0.49-3.4-2.57c-0.1-0.9-0.17-2.22-0.17-3.54c0-1.32,0.07-2.61,0.17-3.54c0.28-2.05,1.35-2.4,3.4-2.57
                c1.74-0.14,3.79-0.21,5.35-0.21c1.56,0,3.58,0.07,5.35,0.21c2.05,0.17,3.09,0.52,3.37,2.57c0.14,0.94,0.21,2.22,0.21,3.54
                C24.53,16.9,24.46,18.22,24.32,19.13z"/>
            <polygon class="st0" points="13.8,18.29 18.45,15.58 13.8,12.91 		"/>
        </g>
    </g>
    </svg>

)

export default YtIcon;