import React, { useState } from "react"
import Header from './header';
import Footer from './footer';
import Icons from './../images/sidebar-img.png';

const Layout = ( { children, ver } ) => {
    let [ activeMenu, setActiveMenu ] = useState(false);

    return (
        <>
        {/* {ver &&
            children
        } */}
        {/* {!ver && */}
            <div className={`layout notranslate`}>
                <Header setActiveMenu={(status) => setActiveMenu(status)} />
                <div className={activeMenu ? "bg-dark" : ""}>
                    { children }
                    <Footer />
                    <div className="sidebar-img">
                        <img src={Icons} al="ikony" />
                    </div>
                </div>
            </div>
        {/* } */}
        </>
    )

}



export default Layout;