const TriangleBtnIcon = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="19px" height="15px">
        <path fill-rule="evenodd"  fill="rgb(255, 214, 43)"
        d="M18.831,7.453 L9.581,14.125 L9.581,7.522 L0.427,14.125 L0.427,0.781 L9.581,7.384 L9.581,0.781 L18.831,7.453 Z"/>
    </svg>
)

export default TriangleBtnIcon;