import React, { useState, useEffect } from "react"
import wwImg from './../images/www.png';
import wwImgX2 from './../images/wwwX2.png';
import wwImgMobile from './../images/ww-mobile.png';
import TriangleBtnIcon from './../svg-components/TriangleBtnIcon';
// import Arrow from './../images/arrow.png';
import AvatarLeft from './../images/wieza-wajfla-img.png';
import AvatarRight from './../images/wzruszajacy-widok-img.png';
import WiezaWajfla from './../images/wieza-wajfla.png';
import WiezaWajflaTablet from './../images/wieza-wajfla-tablet.png';
import WzruszajacyWidok from './../images/wzruszajacy-widok.png';
import WzruszajacyWidokTablet from './../images/wzruszajacy-widok-tablet.png';
import Arrow from './../svg-components/Arrow';
import Poster from './../images/poster.png';
import Play from './../svg-components/Play';
import PlayTablet from './../svg-components/PlayTablet';
import PlayMobile from './../svg-components/PlayMobile';
import OneLeft from './../images/one-left.png';
import OneLeftMobile from './../images/one-left-mobile.png';
import TwoRight from './../images/two-right.png';
import TwoRightTablet from './../images/two-right-tablet.png';
import TwoRightMobile from './../images/two-right-mobile.png';
import ThreeLeft from './../images/three-left.png';
import ThreeLeftMobile from './../images/three-left-mobile.png';
import FourRight from './../images/four-right.png';
import WielkiegoWoraImg from './../images/wielkiego-wora-img.png';
import WingardiumWindozaImg from './../images/wingardium-windoza-img.png';
import WielkiegoWora from './../images/wielkiego-wora.png';
import WielkiegoWoraTablet from './../images/wielkiego-wora-tablet.png';
import WingardiumWindoza from './../images/wingardium-windoza.png';
import WingardiumWindozaTablet from './../images/wingardium-windoza-tablet.png';
import Border from './../images/border.png';
import Title from './../components/title';
import Input from './../components/input';
import Checkbox from './../components/checkbox';
import Button from './../components/button';
// import TransitionBg from './../images/bg-transition.jpg';
import TransitionBg from './../images/bg-transition-new.jpg';
// import TransitionBgTablet from './../images/bg-transition-tablet.jpg';
import TransitionBgTablet from './../images/bg-transition-tablet-new.jpg';
import TransitionBgMobile from './../images/bg-transition-mobile.jpg';
import WwFormImg from './../images/form-ww.png';
import WwInspirationImg from './../images/ww-inspiration.png';
import validateWW from './../services/validateWW';
import Slick from "react-slick";
import onClickScroll from './../services/onClickScroll';
import ApiUrl from './../services/ApiUrl';
import axios from 'axios';
import Popup from './../components/popup';
import Select from './../components/input/select';
import sliderItemsItem from './../images/slider-items-item-1.png';
import sliderItemsItem2 from './../images/slider-items-item-2.png';
import sliderItemsItem3 from './../images/slider-items-item-3.png';
import sliderItemsItem4 from './../images/slider-items-item-4.png';
import sliderItemsItem5 from './../images/slider-items-item-5.png';
import sliderItemsItem6 from './../images/slider-items-item-6.png';
import sliderItemsItem7 from './../images/slider-items-item-7.png';
import sliderItemsItem8 from './../images/slider-items-item-8.png';
import Logo from './../svg-components/Logo';
import Wielokat from './../images/wielokat.svg';
import Regulations from './../images/Regulamin konkursu.pdf';
import Border1 from './../images/border1.svg';
import Border2 from './../images/border2.svg';

const Home = () => {
    let [ load, setLoad ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ ww, setWW ] = useState("");
    let [ name, setName ] = useState("");
    let [ surname, setSurname ] = useState("");
    let [ nick, setNick ] = useState("");
    let [ phone, setPhone ] = useState("");
    let [ email, setEmail ] = useState("");
    let [ image, setImage ] = useState(false);
    let [ reg1, setReg1 ] = useState(false);
    let [ reg2, setReg2 ] = useState(false);
    let [ reg3, setReg3 ] = useState(false);
    let [ clickedPoster, setClickedPoster ] = useState(false);
    let [ reset, setReset ] = useState(false);
    let [ activeWeek, setActiveWeek ] = useState(0);
    let [ activeReward, setActiveReward ] = useState(-1);
    let [ token, setToken ] = useState(false);
    let [ weeks, setWeeks ] = useState(false);
    let [ btnBlocked, setBtnBlocked ] = useState(false);
    let [ popupText, setPopupText ] = useState("");
    let [ errorWWCheck, setErrorWWCheck ] = useState(false);
    let [ blockBtnCheck, setBlockBtnCheck ] = useState(false);
    let [ areUFrom, setAreUFrom ] = useState(false);
    let [ influencer, setInfluencer ] = useState("");

    const options = [
        {
            title: "Z INSTAGRAMA..."
        },
        {
            title: "ZE SKLEPU..."
        },
        {
            title: "OD INFLUENCERA: IMIĘ/NICK..."
        },
    ]

    useEffect(() => {
        if(!load) {
            getWinners();
            getToken();
            setLoad(true);
        }
    }, [load])

    const getWinners = async() => {
        let response = await fetch(`${ApiUrl()}/wp-json/api/v1/winners`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.      
            headers: {
                'Content-Type': `application/json`,
            }
        });
        if (response.status == 200) {
            let res = await response.json();
            setWeeks(res.week)
        }else{
            console.log('err')
        }
    }

    const getToken = async() => {
        let dataAPI = {
            username: "front",
            password: "front",
        }

        let response = await fetch(`${ApiUrl()}/wp-json/jwt-auth/v1/token`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.      
            body: JSON.stringify(dataAPI),
            headers: {
                'Content-Type': `application/json`,
            }
        });
        if ( response.status == 200) {
            let res = await response.json();
            setToken(res.token)
        }else{
            console.log('err')
        }
    }

    const validate = async() => {
        await setForceValidate(true);
        await wait(500);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(!errors) {
            sendForm();
        }
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    const checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.form form .error`);
        if( elements.length > 0 ) {
            let topElement = elements[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement - 10
            });
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const checkWW = async(words) => {
        if(words.length > 2 && words.length < 201 && !blockBtnCheck) {
            await setBlockBtnCheck(true);
            console.log('jadem')
            let response = await fetch(`${ApiUrl()}/wp-json/api/v1/check-ww?ww=${words}`, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.      
                headers: {
                    'Content-Type': `application/json`,
                }
            });
            if (response.status == 200) {
                let res = await response.json();
                if(res.exists) {
                    await setErrorWWCheck("Identyczne hasło zostało już wprowadzone. Podaj inne.");
                    await setForceValidate(true);
                    await wait(100);
                    await setForceValidate(false);
                }else{
                    await setErrorWWCheck(false);
                    await setForceValidate(true);
                    await wait(100);
                    await setForceValidate(false);
                    await onClickScroll("#formularz")
                }
                await wait(2000);
                await setBlockBtnCheck(false);
            }else{
                console.log('err')
            }
        }else if(words.length > 51) {
            await setErrorWWCheck("Maksymalna ilość znaków to 51.");
            await setForceValidate(true);
            await wait(100);
            await setForceValidate(false);
        }
    }

    const sendForm = async() => {
        if(!btnBlocked) {
            await setBtnBlocked(true);
            const formData = new FormData();
            formData.append("first_name", name); 
            formData.append("last_name", surname); 
            formData.append("nickname", nick); 
            formData.append("phone", phone); 
            formData.append("email", email); 
            formData.append("ww", ww);
            formData.append("receipt_photo", image); 
            formData.append("are_u_from", areUFrom != "-1" ? options[areUFrom]?.title : false); 
            formData.append("influencer", influencer); 
            formData.append("reg_1", reg1);
            formData.append("reg_2", reg2);
            formData.append("reg_3", reg3);
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            let call = await axios({
                method: 'post',
                url: `${ ApiUrl() }/wp-json/api/v1/form`,
                config: { headers: {
                    'Content-Type': 'multipart/form-data',
                    // 'Authorization': 'Bearer' + token
                 }},
                data: formData,
            }).then(async res => {
                if(res.status == 200) {
                    setName("");
                    setSurname("");
                    setNick("");
                    setPhone("");
                    setEmail("");
                    setWW("");
                    setImage("");
                    await setReset(true);
                    await wait(100);
                    await setReset(false);
                    setPopupText("Twoje hasło zostało zapisane. Dziękujemy!")
                }else{
                    setPopupText("Coś poszło nie tak, prosimy spróbować ponownie później.")
                }
            }).catch(async error => {
                    setPopupText("Coś poszło nie tak, prosimy spróbować ponownie później.")
                }
            )
            await setBtnBlocked(false);
        }
    }

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        fade: false,
        autoplay: false,
        pauseOnHover: false,
        autoplaySpeed: 6400,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <ArrowSlider />,
        prevArrow: <ArrowSlider />,
        responsive: [{
            breakpoint: 420,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }]
    };

    const settingsInspiration = {
        dots: false,
        arrows: false,
        infinite: true,
        fade: false,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 6400,
        speed: 1500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [{
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
                centerMode: true,
            }
        }]
    };

    const settingsWinners = {
        dots: false,
        arrows: false,
        infinite: true,
        fade: false,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 6400,
        speed: 1500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [{
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }]
    };

    const ArrowSliderItems = (props) => {
        const { className, style, onClick, type } = props;
        let text = "<"
        if(type == "next") {
            text = ">";
        }
        return (
            <div
                className={className}
                style={{ ...style }}
                onClick={onClick} >
                    <div className={`arrow ${type}`}>
                        <p>{text}</p>
                    </div>
            </div>
        );
    }

    const settingsSliderItems = {
        dots: false,
        arrows: true,
        infinite: true,
        fade: false,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 6400,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <ArrowSliderItems type="next" />,
        prevArrow: <ArrowSliderItems type="prev" />,
        // responsive: [{
        //     breakpoint: 500,
        //     settings: {
        //         fade: true
        //     }
        // }]
    };

    function ArrowSlider(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style }}
                onClick={onClick} >
                    <div className="arrow-slider-triangle">
                        <div className="play"></div>
                    </div>
            </div>
        );
    }

    function romanize (num) {
        if (!+num)
            return false;
        var digits = String(+num).split(""),
            key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                   "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                   "","I","II","III","IV","V","VI","VII","VIII","IX"],
            roman = "",
            i = 3;
        while (i--)
            roman = (key[+digits.pop() + (i * 10)] || "") + roman;
        return Array(+digits.join("") + 1).join("M") + roman;
    }

    return (
        <>
            {popupText &&
                <Popup text={popupText} close={() => setPopupText("")} />
            }
            <section class="rewards">
                <div class="container">
                    <div className="row banner">
                        <div className="box">
                            <div className="pos">
                                {/* <div className="normal-box">
                                    <p>CZAS PRZYJMOWANIA ZGŁOSZEŃ:</p>
                                    <h2>22.04.2024 – 16.06.2024</h2>
                                </div> */}
                                {/* <div className="normal-box small-tablet">
                                    <p>W KONKURSIE BIORĄ UDZIAŁ</p>
                                    <h2>WSZYSTKIE PRODUKTY WW</h2>
                                </div> */}
                            </div>
                            {/* <div className="pos2">
                                <div className="other-box">
                                    <img src={Wielokat} />
                                    <h2>W<br/>KONKURSIE<br/>BIORĄ<br/>UDZIAŁ<br/>WSZYSTKIE<br/>PRODUKTY<br/>WW</h2>
                                </div>
                            </div> */}
                            <div className="pos3">
                                <div className="other-box">
                                    <img src={Border1} />
                                    <img src={Border2} />
                                    <h2>KONKURS<br/>ZAKOŃCZONY<br/><div className="margin"></div>DZIĘKUJEMY ZA UDZIAŁ<br/>W ZABAWIE!</h2>
                                </div>
                            </div>
                            <p>Co znaczy</p>
                            <img className="desktop" src={wwImg} srcSet={ `${ wwImg } 1x, ${ wwImgX2 } 2x` } alt="ww" />
                            <img className="tablet" src={wwImg} srcSet={ `${ wwImg } 1x, ${ wwImgX2 } 2x` } alt="ww" />
                            <img className="mobile" src={wwImgMobile} srcSet={ `${ wwImgMobile } 1x, ${ wwImgX2 } 2x` } alt="ww" />
                            {/* <img className="desktop" src={wwImg} alt="ww" />
                            <img className="tablet" src={wwImg} alt="ww" />
                            <img className="mobile" src={wwImgMobile} alt="ww" /> */}
                            <p className="question-mark">?</p>
                        </div>
                        <div className="box">
                            <h3>WYMYŚLAJ I WALCZ<br />O NAGRODY</h3>
                            {/* <label for="ww-input">WPISZ SWOJĄ ODPOWIEDŹ:</label>
                            <div className="input-wrapper">
                                <input type="text" id="ww-input" value={ww} placeholder="W... W..." onChange={(e) => setWW(validateWW(e.target.value))} onBlur={(e) => checkWW(e.target.value)} onKeyDown={(e) => e.keyCode == 13 && checkWW(ww) } />
                                <div className="icon-svg" onClick={() => checkWW(ww)}>
                                    <TriangleBtnIcon />
                                </div>
                                { errorWWCheck &&
                                    <p className="error-text">{ errorWWCheck }</p>
                                }
                            </div>  */}
                            <div className="arrow-icon" id="wideo">
                                {/* <img src={Arrow} alt="strzałka" /> */}
                                <Arrow />
                            </div>
                            <div className="avatar avatar-left">
                                <div className="image-wrapper">
                                    <img src={AvatarLeft} alt="avatar" />
                                </div>
                                <div className="ww-box">
                                    <img src={WiezaWajfla} className="" alt="wieża wajfla" />
                                    {/* <img src={WiezaWajflaTablet} className="tablet" alt="wieża wajfla" /> */}
                                </div>
                            </div>
                            <div className="avatar avatar-right">
                                <div className="image-wrapper">
                                    <img src={AvatarRight} alt="avatar" />
                                </div>
                                <div className="ww-box">
                                    <img src={WzruszajacyWidok} className="" alt="wieża wajfla" />
                                    {/* <img src={WzruszajacyWidokTablet} className="tablet" alt="wieża wajfla" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row video">
                        <div className="video-wrapper">
                            {!clickedPoster &&
                                <div className="poster" onClick={() => setClickedPoster(true)}>
                                    <img src={Poster} alt="poster" />
                                    <div className="play desktop">
                                        <Play />
                                    </div>
                                    <div className="play tablet">
                                        <PlayTablet />
                                    </div>
                                    <div className="play mobile">
                                        <PlayMobile />
                                    </div>
                                </div>
                            }
                            {clickedPoster &&
                                <div className="video">
                                    <iframe width="868" height="468" src="https://www.youtube.com/embed/cnHTmYlBAq8?si=VWX_xCFj1wzJ-QNC" frameborder="0" allowfullscreen=""></iframe>
                                </div>
                            }
                        </div>
                        <div className="pos">
                            <div className="pos-item one-left animate-item">
                                <img src={OneLeft} className="desktop" alt="ww" />
                                <img src={OneLeft} className="tablet" alt="ww" />
                                <img src={OneLeftMobile} className="mobile" alt="ww" />
                            </div>
                            <div className="pos-item two-right">
                                <img src={TwoRight} className="desktop" alt="ww" />
                                <img src={TwoRightTablet} className="tablet" alt="ww" />
                                <img src={TwoRightMobile} className="mobile" alt="ww" />
                            </div>
                            <div className="pos-item three-left animate-item">
                                <img src={ThreeLeft} className="desktop" alt="ww" />
                                <img src={ThreeLeft} className="tablet" alt="ww" />
                                <img src={ThreeLeftMobile} className="mobile" alt="ww" />
                            </div>
                            <div className="pos-item four-right">
                                <img src={FourRight} alt="ww" />
                            </div>
                            <div className="pos-item arrow-icon arrow" id="nagrody">
                                <Arrow />
                            </div>
                            <div className="pos-item avatar avatar-left">
                                <div className="image-wrapper">
                                    <img src={WingardiumWindozaImg} alt="avatar" />
                                </div>
                                <div className="ww-box">
                                    <img src={WingardiumWindoza} className="desktop" alt="wieża wajfla" />
                                    <img src={WingardiumWindozaTablet} className="tablet" alt="wieża wajfla" />
                                </div>
                            </div>
                            <div className="pos-item avatar avatar-right">
                                <div className="image-wrapper">
                                    <img src={WielkiegoWoraImg} alt="avatar" />
                                </div>
                                <div className="ww-box">
                                    <img src={WielkiegoWora} className="desktop" alt="wieża wajfla" />
                                    <img src={WielkiegoWoraTablet} className="tablet" alt="wieża wajfla" />
                                </div>
                            </div>
                            <div className="pos-item arrow-icon arrow-two">
                                <Arrow />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="border">
                    <img src={Border} alt="" />
                </div> */}
            </section>
            <section className="rules" id="zasady">
                <div className="container">
                    <Title title="< ZASADY >" info="W TERMINIE 22.04.2024 – 16.06.2024:" className="inborder-text" />
                    <h4 className="mobile-title">W TERMINIE 22.04.2024 – 16.06.2024:</h4>
                    <div className="steps-wrapper">
                        <div className="step">
                            <div className="number">1</div>
                            <div className="text">
                                <p>Kup dowolny produkt marki WW w sklepie lub online w czasie trwania konkursu.</p>
                            </div>
                        </div>
                        <div className="step">
                            <div className="number">2</div>
                            <div className="text">
                                <p>Zachowaj dowód zakupu i zrób zdjęcie lub skan. W konkursie mogą wziąć udział osoby 13+. Szczegóły w <a href={Regulations} target="_blank">regulaminie</a>.</p>
                            </div>
                        </div>
                        <div className="step">
                            <div className="number">3</div>
                            <div className="text">
                                <p>Wykmiń "co znaczy ww?"<br/>i zarejestruj odpowiedź w formularzu zgłoszeniowym.</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-wrapper">
                        <h3>NAJCIEKAWSZE POMYSŁY NAGRODZIMY WYPASIONYMI NAGRODAMI, A 24 NAJLEPSZE HASŁA ZNAJDZIESZ NA LIMITOWANEJ SERII OPAKOWAŃ W SKLEPACH!</h3>
                    </div>
                </div>
            </section>
            <section className="form" id="formularz">
                <div className="container">
                    <div className="desktop">
                        <Title text="<h2>KONKURS ZAKOŃCZONY.<br/>DZIĘKUJEMY ZA UDZIAŁ W ZABAWIE!<br/><p>WCINAJ WAFLA I OBSERWUJ NAS W INTERNECIE I SKLEPACH!</p></h2>" html />
                    </div>
                    <div className="tablet">
                        <Title text="<h2>KONKURS ZAKOŃCZONY.<br/>DZIĘKUJEMY ZA UDZIAŁ W ZABAWIE!<br/><p>WCINAJ WAFLA I OBSERWUJ NAS W INTERNECIE I SKLEPACH!</p></h2>" html />
                    </div>
                    <div className="mobile">
                        <Title text="<h2>KONKURS<br/>ZAKOŃCZONY.<br/>DZIĘKUJEMY<br/>ZA UDZIAŁ<br/>W ZABAWIE!<br/><p>WCINAJ WAFLA I OBSERWUJ NAS<br/>W INTERNECIE I SKLEPACH!</p></h2>" html />
                    </div>
                    {/* <div className="header-wrapper">
                        <h2>CO ZNACZY WW?</h2>
                    </div>
                    <form>  
                        <div className="row">
                            <Input  required
                                    name="WW"
                                    placeholder="W... W..."
                                    value={ww}
                                    forceValidate={forceValidate}
                                    setFunction={(value) => setWW(validateWW(value))} 
                                    reset={ reset }
                            />
                            <Input  required
                                    name="name"
                                    placeholder="IMIĘ"
                                    value={name}
                                    forceValidate={forceValidate}
                                    setFunction={(value) => setName(value.length == 1 ? value.toUpperCase() : value)} 
                                    regExp={/^[\s\p{L}]+$/u}
                                    reset={ reset }
                            />
                            <Input  required
                                    name="surname"
                                    placeholder="NAZWISKO"
                                    value={surname}
                                    forceValidate={forceValidate}
                                    setFunction={(value) => setSurname(value.length == 1 ? value.toUpperCase() : value)} 
                                    regExp={/^[\s\p{L}]+$/u}
                                    reset={ reset }
                            />
                            <Input  required
                                    name="nick"
                                    placeholder="PSEUDONIM"
                                    value={nick}
                                    forceValidate={forceValidate}
                                    setFunction={(value) => setNick(value)} 
                                    reset={ reset }
                            />
                            <Input  placeholder="NR TELEFONU"
                                    name="phone"
                                    value={phone}
                                    // regExp={/^[+0-9]{9,12}$/}
                                    regExp={/^[0-9]*$/}
                                    minLength="9"
                                    maxLength="12"
                                    forceValidate={forceValidate}
                                    setFunction={(value) => setPhone(value)} 
                                    reset={ reset }
                            />
                            <Input  required
                                    name="email"
                                    placeholder="ADRES E-MAIL"
                                    value={email}
                                    regExp={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/}
                                    forceValidate={forceValidate}
                                    setFunction={(value) => setEmail(value)} 
                                    reset={ reset }
                            />
                            <Input  required
                                    name="file-upload"
                                    type="file"
                                    placeholder="ZDJĘCIE PARAGONU"
                                    value={image}
                                    forceValidate={forceValidate}
                                    setFunction={(value) => setImage(value)} 
                                    reset={ reset }
                            />
                            <Select data={ options } 
                                selectIndex={ areUFrom }
                                reset={reset}
                                id="how-do-u-know"
                                placeholder={"SKĄD WIESZ O KONKURSIE?"}
                                setFunction={ ( val ) => setAreUFrom(val)} />
                            {areUFrom == 2 &&
                                <Input  required
                                    name="influencer"
                                    placeholder="Influencer"
                                    value={influencer}
                                    forceValidate={forceValidate}
                                    setFunction={(value) => setInfluencer(value)} 
                                    reset={ reset }
                                />
                            }
                        </div>
                        <div className="row">
                            <Checkbox text={"Potwierdzam, że zapoznałam/em się z treścią Regulaminu Konkursu i akceptuję jego treść oraz wyrażam zgodę na udział w Konkursie.<br/><br/>Oświadczam, że mam ukończone 18 lat” oraz w razie ukończonych lat 13, a nieukończonych lat 18  „Oświadczam, że mam ukończone 13 lat i posiadam zgodę mojego przedstawiciela ustawowego na udział w Konkursie, której scan/zdjęcie przesyłam na adres e-mail: KonkursWW@digitalkingdom.pl.<br/><br/>Oświadczam, że nie jestem osobą wyłączoną z udziału w Konkursie."} 
                                required={ true }
                                id="reg1"
                                forceValidate={ forceValidate }
                                setFunction={ ( val ) => setReg1(val)}
                                reset={ reset } />
                            <Checkbox text={"Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z powszechnie obowiązującymi przepisami prawa przez Administratora danych osobowych wskazanego w punkcie 58 Regulaminu Konkursu oraz Organizatora zgodnie z treścią na zasadach określonych w punktach 47–58 Regulaminu dla celów związanych z organizacją i przeprowadzeniem Konkursu. Moje dane osobowe udostępniam dobrowolnie oraz wiem, że przysługuje mi prawo dostępu do podanych przeze mnie danych, prawo żądania ich sprostowania, usunięcia lub ograniczenia ich przetwarzania, wniesienia sprzeciwu wobec ich przetwarzania, a także prawo żądania ich przenoszenia oraz prawo do cofnięcia zgody na ich przetwarzanie w dowolnym momencie, co pozostanie bez wpływu na zgodność z prawem ich przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. Podanie danych osobowych jest dobrowolne, jednak odmowa ich podania uniemożliwia wzięcie udziału w Konkursie."} 
                                required={ true }
                                id="reg2"
                                forceValidate={ forceValidate }
                                setFunction={ ( val ) => setReg2(val)}
                                reset={ reset } />
                            <Checkbox text={"Wyrażam zgodę na opublikowanie na stronie konkursowej mojej Odpowiedzi na Zadanie Konkursowe oraz mojego pseudonimu w celu opublikowania listy laureatów Konkursu, a także, w razie wygranej I stopnia, wyrażam zgodę na umieszczenie Odpowiedzi na Zadanie Konkursowe i mojego pseudonimu na limitowanej serii opakowań wafelków WW marki E. Wedel dostępnych od września 2024 roku w Sklepach oraz ich wykorzystanie do celów promocji konkursu i Produktów Konkursowych.<br/><br/>Udzielam zezwolenia na korzystanie z  Odpowiedzi na Zadanie Konkursowe na zasadach określonych w Regulaminie Konkursu.<br/><br/>Oświadczam, że Odpowiedź na Zadanie Konkursowe stanowi przejaw mojej indywidualnej i oryginalnej twórczości oraz że przysługują mi do niej wszelkie prawa, które nie są w żaden sposób ograniczone ani obciążone, jak również, że rozporządzenie przez mnie Odpowiedzią na Zadanie Konkursowe po jej zamieszczeniu nie wpłynie na uprawnienia Organizatora."} 
                                required={ true }
                                id="reg3"
                                forceValidate={ forceValidate }
                                setFunction={ ( val ) => setReg3(val)}
                                reset={ reset } />
                        </div>
                        <div className="row">
                            <Button id="submit-form" text="WYŚLIJ ZGŁOSZENIE" setFunction={() => validate()} />
                        </div>
                    </form> */}
                </div>
                <img className="transition-bg desktop" src={TransitionBg} alt="" />
                <img className="transition-bg tablet" src={TransitionBgTablet} alt="" />
                <img className="transition-bg mobile" src={TransitionBgMobile} alt="" />
                <div className="ww-img desktop animate-item">
                    <img src={WwFormImg} alt="" />
                </div>
            </section>
            <section className="inspiration" id="inspiracje">
                <div className="container">
                    <Title title="< INSPIRACJE >" className="without-border" />
                    <div className="inspiration-items-wrapper">
                        <div className="item">
                            <p>„WIEŻA WAJFLA”</p>
                        </div>
                        <div className="item">
                            <p>„WYSOKA WANNA”</p>
                        </div>
                        <div className="item">
                            <p>„WCINAJ WAFLA”</p>
                        </div>
                        <div className="item">
                            <p>„WARKNIJ WYDECHEM”</p>
                        </div>
                        <div className="item">
                            <p>„WEŹ WYJDŹ”</p>
                        </div>
                        <div className="item">
                            <p>„WIDZIAŁEM WSZYSTKO”</p>
                        </div>
                    </div>
                    <div className="inspiration-items-wrapper inspiration-items-wrapper-slider">
                        <Slick { ...settingsInspiration }>
                            <div className="item">
                                <p>„WIEŻA WAJFLA”</p>
                            </div>
                            <div className="item">
                                <p>„WYSOKA WANNA”</p>
                            </div>
                            <div className="item">
                                <p>„WCINAJ WAFLA”</p>
                            </div>
                            <div className="item">
                                <p>„WARKNIJ WYDECHEM”</p>
                            </div>
                            <div className="item">
                                <p>„WEŹ WYJDŹ”</p>
                            </div>
                            <div className="item">
                                <p>„WIDZIAŁEM WSZYSTKO”</p>
                            </div>
                        </ Slick>
                    </div>
                </div>
                <div className="ww-img desktop animate-item">
                    <img src={WwInspirationImg} alt="" />
                </div>
            </section>
            {weeks &&
                <section className="winners" id="zwyciezcy">
                    <div className="container">
                        <Title title="< ZWYCIĘZCY >" className="without-border" />
                        <div className="weeks-wrapper">
                            <Slick { ...settings }>
                                {weeks.map((week, index) => (
                                    <div className={`week ${activeWeek == index ? 'active' : ''}`} key={index}>
                                        <div className="week-number" onClick={() => setActiveWeek(index)}>{week.title}</div>
                                    </div>
                                ))}
                            </Slick>
                        </div>
                        <div className="rewards-wrapper">
                            <div className={`reward ${activeReward == 0 ? 'active' : ''}`} onClick={() => setActiveReward(0)}>I NAGRODA</div>
                            <div className={`reward ${activeReward == 1 ? 'active' : ''}`} onClick={() => setActiveReward(1)}>II NAGRODA</div>
                            <div className={`reward ${activeReward == 2 ? 'active' : ''}`} onClick={() => setActiveReward(2)}>III NAGRODA</div>
                        </div>
                        {/* {activeReward >= 0 && */}
                        <div className="winners-list">
                            {activeReward == 2 &&
                                <div className="scroll-list">
                                    {weeks[activeWeek]?.awards[activeReward]?.award?.map((award, i) => (
                                        <div className="row" key={i}>
                                            <p>{romanize(++i)} / {award.title} / {award.caption}</p>
                                        </div>
                                    ))}
                                </div>
                            }
                            {(activeReward == 1 || activeReward == 0) &&
                            <>
                                <div className="items">
                                    {weeks[activeWeek]?.awards[activeReward]?.award?.map((award, ii) => (
                                        <div className="item" key={ii}>
                                            <div className={`row ${award.title.length >= 16 && award.title.length < 23 ? 'small-font' : (award.title.length >= 23 ? 'big-small-font' : '')}`}>
                                                <p className="place">{++ii}.</p>
                                                {/* <p class="ww">„{award.title}”</p> */}
                                                <p class="ww">{award.title}</p>
                                            </div>
                                            <div className="row">
                                                <p className="author">{award.caption}</p>
                                            </div>
                                        </div> 
                                    ))}
                                </div>
                                <div className="items items-slider">
                                    <Slick { ...settingsWinners }>
                                        {weeks[activeWeek]?.awards[activeReward]?.award?.map((award, iii) => (
                                            <div className="item" key={iii}>
                                                <div className={`row ${award.title.length >= 16 && award.title.length < 19 ? 'small-font' : (award.title.length >= 19 ? 'big-small-font' : '')}`}>
                                                    <p className="place">{++iii}.</p>
                                                    {/* <p class="ww">„{award.title}”</p> */}
                                                    <p class="ww">{award.title}</p>
                                                </div>
                                                <div className="row">
                                                    <p className="author">{award.caption}</p>
                                                </div>
                                            </div> 
                                        ))}
                                    </Slick>
                                </div>
                            </>
                            }
                        </div>
                        {/* } */}
                    </div>
                </section>
            }
            <section className="slider-items">
                <div className="container">
                    <Title title="PRODUKTY BIORĄCE UDZIAŁ W KONKURSIE:" className="without-border" />
                    <div className="slider-items-wrapper">
                        <Slick { ...settingsSliderItems }>
                            <div className="item">
                                <img src={sliderItemsItem} alt="" />
                            </div> 
                            <div className="item">
                                <img src={sliderItemsItem2} alt="" />
                            </div> 
                            <div className="item">
                                <img src={sliderItemsItem3} alt="" />
                            </div> 
                            <div className="item">
                                <img src={sliderItemsItem4} alt="" />
                            </div> 
                            <div className="item">
                                <img src={sliderItemsItem5} alt="" />
                            </div> 
                            <div className="item">
                                <img src={sliderItemsItem6} alt="" />
                            </div> 
                            <div className="item">
                                <img src={sliderItemsItem7} alt="" />
                            </div> 
                            <div className="item">
                                <img src={sliderItemsItem8} alt="" />
                            </div> 
                        </Slick>
                    </div>
                    <div className="info-wrapper">
                        <p>KUPUJ W SKLEPACH STACJONARNYCH LUB ONLINE, <br />W TYM <br className="mobile" />W OFICJALNYM SKLEPIE <br className="mobile" />E. WEDEL NA ALLEGRO:</p>
                    </div>
                    <div className="btn-wedel-wrapper">
                        <a href="https://allegro.pl/uzytkownik/Wedel_Pijalnie" target="_blank" className="wrapper">
                            <div className="col">
                                <Logo />
                            </div>
                            <div className="col">
                                <p>Oficjalny sklep</p>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </>
    )

}



export default Home;