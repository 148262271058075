const PlayTablet = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="52px" height="61px">
        <path fill-rule="evenodd"  fill="rgb(255, 214, 43)"
        d="M51.842,30.263 L0.248,60.342 L0.248,0.185 L51.842,30.263 Z"/>
    </svg>
)

export default PlayTablet;