import React, { useState, useEffect } from "react"
import videoIcon from './../../images/wideo-menu-icon.png';
// import logo from './../../images/logo.png';
import logo from './../../images/wedel_logo.svg';
import onClickScroll from './../../services/onClickScroll';
import HoverWW from './../../svg-components/WwMenu';
import Regulations from './../../images/Regulamin konkursu.pdf';

const Header = ( { setActiveMenu } ) => {
    let [ active, setActive ] = useState(false);

    useEffect(() => {
        setActiveMenu(active);
    }, [active])
    
    return (
        <>
            <header>
                <div className="container">
                    <div className="wrapper">
                        <nav className="desktop-nav">
                            <ul>
                                <li>
                                    <a onClick={() => {onClickScroll("#wideo")}}>
                                        {/* <img src={videoIcon} alt="wideo" /> */}
                                        Wideo
                                    </a>
                                    <HoverWW />
                                </li>
                                <li>
                                    <a onClick={() => onClickScroll("#nagrody")}>Nagrody</a><HoverWW />
                                </li>
                                <li>
                                    <a onClick={() => onClickScroll("#zasady")}>Zasady</a><HoverWW />
                                </li>
                                <li className="logo">
                                    <img src={logo} alt="wedel" />
                                </li>
                                <li>
                                    <a onClick={() => onClickScroll("#inspiracje")}>Inspiracje</a><HoverWW />
                                </li>
                                <li>
                                    <a onClick={() => onClickScroll("#zwyciezcy")}>Zwycięzcy</a><HoverWW />
                                </li>
                                <li>
                                    <a href={Regulations} target="_blank">Regulamin</a><HoverWW />
                                </li>
                            </ul>
                        </nav>
                        <nav className="mobile-nav">
                            <div className="logo">
                                <img src={logo} alt="wedel" />
                            </div>
                            <div class="mobile-hamburger">
                                <div class="hamburger-wrap">
                                    <div class={`hamburger ${active ? 'is-active' : ''}`} onClick={() => setActive(!active)}>
                                        <span class="line" />
                                        <span class="line" />
                                        <span class="line" />
                                        <span class="line" />
                                    </div>
                                </div>
                            </div>
                            <ul className={`${active ? 'is-active' : ''}`}>
                                <li>
                                    <a onClick={() => {onClickScroll("#wideo"); setActive(false)}}>Wideo</a>
                                </li>
                                <li>
                                    <a onClick={() => {onClickScroll("#nagrody"); setActive(false)}}>Nagrody</a>
                                </li>
                                <li>
                                    <a onClick={() => {onClickScroll("#zasady"); setActive(false)}}>Zasady</a>
                                </li>
                                <li>
                                    <a onClick={() => {onClickScroll("#inspiracje"); setActive(false)}}>Inspiracje</a>
                                </li>
                                <li>
                                    <a onClick={() => {onClickScroll("#zwyciezcy"); setActive(false)}}>Zwycięzcy</a>
                                </li>
                                <li>
                                    <a href={Regulations} target="_blank">Regulamin</a><HoverWW />
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    )

}



export default Header;