const FbIcon = () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 31.16 31.21" xmlSpace="preserve">
        <g id="BACKGROUND">
        </g>
        <g id="Layer_1">
            <g>
                <path class="st0" d="M15.58,0.04c-8.6,0-15.56,6.97-15.56,15.56s6.97,15.56,15.56,15.56s15.56-6.97,15.56-15.56
                    S24.18,0.04,15.58,0.04z M20.12,9.4h-1.61c-0.89,0-1.82,0.55-1.82,1.53v2.63h3.31l-0.55,3.48h-2.76v8.61h-3.73v-8.61H9.81v-3.48
                    h3.14v-2.59c0-2.59,1.53-4.75,4.28-4.58l2.88,0.13V9.4z"/>
            </g>
        </g>
    </svg>

)

export default FbIcon;