const InstaIcon = () => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 31.16 31.21" xmlSpace="preserve">
        <g id="BACKGROUND">
        </g>
        <g id="Layer_1">
            <g>
                <path class="st0" d="M19.71,8.18h-8.25c-1.86,0-3.37,1.51-3.37,3.37v8.21c0,1.86,1.51,3.37,3.37,3.37h8.25
                    c1.86,0,3.37-1.51,3.37-3.37v-8.21C23.08,9.69,21.57,8.18,19.71,8.18z M15.6,20.58h-0.04c-2.68,0-4.88-2.2-4.88-4.93
                    c0-2.68,2.2-4.93,4.88-4.93h0.04c2.68,0,4.93,2.25,4.93,4.93C20.53,18.38,18.28,20.58,15.6,20.58z M20.44,11.85
                    c-0.61,0-1.04-0.48-1.04-1.04c0-0.56,0.43-1.04,1.04-1.04c0.56,0,1.04,0.48,1.04,1.04C21.48,11.37,21,11.85,20.44,11.85z"/>
                <path class="st0" d="M15.6,12.41h-0.04c-1.77,0-3.24,1.47-3.24,3.24c0,1.82,1.47,3.28,3.24,3.28h0.04c1.77,0,3.24-1.47,3.24-3.28
                    C18.84,13.88,17.37,12.41,15.6,12.41z"/>
                <path class="st0" d="M15.58,0.04c-8.6,0-15.56,6.97-15.56,15.56s6.97,15.56,15.56,15.56c8.6,0,15.56-6.97,15.56-15.56
                    S24.18,0.04,15.58,0.04z M24.63,19.8c0,2.68-2.2,4.88-4.88,4.88h-8.34c-2.68,0-4.88-2.2-4.88-4.88v-8.3c0-2.68,2.2-4.88,4.88-4.88
                    h8.34c2.68,0,4.88,2.2,4.88,4.88V19.8z"/>
            </g>
        </g>
    </svg>
)

export default InstaIcon;